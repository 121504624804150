import React, { useRef } from 'react';
import { 
  Box, ChakraProvider, VStack, HStack, Button, useBreakpointValue, Flex, Spacer, 
  Menu, MenuButton, MenuList, MenuItem, IconButton, Image, Text 
} from '@chakra-ui/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FcPhone } from "react-icons/fc";  // Import des icônes
import theme from './theme';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'; // Importer Router et Route
import Presentation from './components/Presentation';
import ContactUs from './components/ContactUs';
import FeaturesSection from './components/FeaturesSection';
import AboutSection from './components/AboutSection';
import ObjectivesSection from './components/ObjectivesSection';
import TeamSection from './components/TeamSection';
import MissionSection from './components/MissionSection';
import SolutionSection from './components/SolutionSection';
import KeyFeaturesSection from './components/KeyFeaturesSection';
import ClientBenefitsSection from './components/ClientBenefitsSection';
import ServiceOfferSection from './components/ServiceOfferSection';
import CGU from './CGU'; // Importer le composant CGU

const App: React.FC = () => {
  const presentationRef = useRef<HTMLDivElement>(null);
  const demonstrationRef = useRef<HTMLDivElement>(null);
  const infoSectionsRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (section: React.RefObject<HTMLDivElement>) => {
    if (section.current) {
      const yOffset = -100;
      const y = section.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <ChakraProvider theme={theme}>
      <GoogleReCaptchaProvider reCaptchaKey="6LfXqSIqAAAAAFPrGZUy_ZU1R4etKGN2KksZR1Ii">
        <Router> {/* Ajouter le Router ici */}
          <Box position="fixed" top="0" width="100%" bg="transparent" zIndex="1000" boxShadow="sm">
            <Flex justify="space-between" align="center" padding={4} mx="auto" mt={4} maxWidth="90%">
              <HStack spacing={4}>
                <Image 
                  src="/logoprotecto.png" 
                  alt="Logo Ordo" 
                  boxSize="50px" 
                  borderRadius="full" 
                  boxShadow="md" 
                  onClick={() => scrollToSection(presentationRef)} 
                  cursor="pointer" 
                />
              </HStack>
              
              {isSmallScreen ? (
                <Flex justify="flex-end" width="100%">
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      aria-label="Ouvrir le menu"
                      icon={<GiHamburgerMenu />}
                      variant="outline"
                      bg="white"
                      borderRadius="full"
                      boxShadow="md"
                      mr="4"  
                    />
                    <MenuList borderRadius="md" boxShadow="lg" mt={2}>
                      <MenuItem _hover={{ bg: 'gray.100' }}>
                        <HStack spacing={2} align="center">
                          <FcPhone size={24} />
                          <Text fontWeight="bold" color="gray.800" fontSize="lg">07 87 78 79 20</Text>
                        </HStack>
                      </MenuItem>
                      <MenuItem onClick={() => scrollToSection(presentationRef)} _hover={{ bg: 'gray.100' }}>
                        Présentation
                      </MenuItem>
                      <MenuItem onClick={() => scrollToSection(demonstrationRef)} _hover={{ bg: 'gray.100' }}>
                        Équipe dirigeante
                      </MenuItem>
                      <MenuItem onClick={() => scrollToSection(infoSectionsRef)} _hover={{ bg: 'gray.100' }}>
                        Notre solution logicielle
                      </MenuItem>
                      <MenuItem onClick={() => scrollToSection(contactRef)} _hover={{ bg: 'gray.100' }}>
                        Contactez-nous
                      </MenuItem>
                      <MenuItem as={Link} to="/cgu" _hover={{ bg: 'gray.100' }}>
                        CGU
                      </MenuItem> {/* Lien vers CGU */}
                    </MenuList>
                  </Menu>
                </Flex>
              ) : (
                <HStack spacing={8}>
                  <HStack spacing={8} justify="center" borderRadius="full" bg="white" boxShadow="md" p={4} height="60px">
                    <Button variant="link" color="gray.800" fontWeight="bold" fontSize="lg" onClick={() => scrollToSection(presentationRef)}>
                      Présentation
                    </Button>
                    <Button variant="link" color="gray.800" fontWeight="bold" fontSize="lg" onClick={() => scrollToSection(demonstrationRef)}>
                      Équipe dirigeante
                    </Button>
                    <Button variant="link" color="gray.800" fontWeight="bold" fontSize="lg" onClick={() => scrollToSection(infoSectionsRef)}>
                      Notre solution logicielle
                    </Button>
                    <Button variant="link" color="gray.800" fontWeight="bold" fontSize="lg" onClick={() => scrollToSection(contactRef)}>
                      Contactez-nous
                    </Button>
                    <Button variant="link" color="gray.800" fontWeight="bold" fontSize="lg" as={Link} to="/cgu">
                      CGU
                    </Button> {/* Lien vers CGU */}
                  </HStack>
                  <Spacer />
                  <HStack spacing={4}>
                    <HStack spacing={2} align="center">
                      <FcPhone size={24} />
                      <Text fontWeight="bold" color="gray.800" fontSize="lg">07 87 78 79 20</Text>
                    </HStack>
                    <Button bg="white" variant="outline" colorScheme="blue" borderRadius="full" fontSize="lg" height="60px" onClick={() => scrollToSection(infoSectionsRef)}>
                      Nos produits
                    </Button>
                    <Button colorScheme="blue" bg="blue.500" color="white" borderRadius="full" fontSize="lg" height="60px" onClick={() => scrollToSection(contactRef)}>
                      Contact
                    </Button>
                  </HStack>
                </HStack>
              )}
            </Flex>
          </Box>
          <VStack spacing={0} align="stretch" mt="120px">
            <Routes>
              <Route path="/" element={
                <>
                  <Box id="presentation" ref={presentationRef}>
                    <Presentation onDiscoverClick={() => scrollToSection(demonstrationRef)} />
                  </Box>
                  <FeaturesSection />
                  <AboutSection />
                  <ObjectivesSection />
                  <Box id="demonstration" ref={demonstrationRef}>
                    <TeamSection />
                  </Box>
                  <MissionSection />
                  <Box id="info-sections" ref={infoSectionsRef}>
                    <SolutionSection />
                  </Box>
                  <KeyFeaturesSection />
                  <ClientBenefitsSection />
                  <ServiceOfferSection />
                  <Box id="contact" ref={contactRef}>
                    <ContactUs />
                  </Box>
                </>
              } />
              <Route path="/cgu" element={<CGU />} /> {/* Route CGU */}
            </Routes>
          </VStack>
        </Router>
      </GoogleReCaptchaProvider>
    </ChakraProvider>
  );
};

export default App;
